<script setup lang="ts">
import { computed, ref } from 'vue';
import { useAlert } from '../DfAlert/DfAlert.vue';
import { errorText } from '/@utilities/api';
import { validateForm, ValidationRule } from '/@utilities/form';

const emit = defineEmits(['submit']);

const props = defineProps<{
  submit: (args?: any) => Promise<any>;

  formValues?: any;
  formValidation?: ValidationRule[];

  onSuccess?: (args?: any) => any;
  onError?: (args?: any) => any;

  elevate?: boolean;
  disabled?: boolean;
}>();

const alert = useAlert();

const sending = ref(false);
const hasTriedToSubmit = ref(false);

const validationMessage = computed(() => {
  const { message } = validateForm(props.formValidation);
  return message;
});

function send(event: Event) {
  emit('submit', event);

  const { ok } = validateForm(props.formValidation);

  if (!ok) {
    hasTriedToSubmit.value = true;
    return;
  }

  sending.value = true;

  return props
    .submit()
    .then((res: any) => {
      return props.onSuccess ? props.onSuccess(res) : null;
    })
    .catch((error: any) => {
      const e: string = props.onError ? props.onError(error) : errorText();

      alert.write(e);

      return props.onError ? e : error;
    })
    .finally(() => {
      sending.value = false;
    });
}
</script>

<template>
  <form :class="{ form: elevate }" v-on:submit.stop.prevent="send">
    <df-alert v-model="alert" />

    <div v-if="hasTriedToSubmit && validationMessage" class="validation">
      <df-icon code="f06a" />
      {{ validationMessage }}
    </div>
    <slot name="default"></slot>

    <div class="button-group">
      <df-button type="submit" :disabled="sending || disabled" primary elevate>
        <slot name="submittext">Lagre</slot>

        <template v-slot:icon>
          <df-loading v-if="sending" no-float />
        </template>
      </df-button>

      <slot name="buttons" :disabled="sending || disabled" :send></slot>
    </div>

    <slot name="footer"></slot>
  </form>
</template>

<style scoped>
form {
  display: grid;
  gap: var(--gap-md);
}

.form {
  background-color: var(--color-cardbg);
  padding: var(--gap-md);
  border-radius: var(--radius-sm);
  box-shadow: var(--shadow-md);
}

.validation {
  background-color: var(--color-danger_bg);
  padding: var(--gap-md);
  color: var(--color-danger_text);
  border-radius: var(--radius-sm);
  font-weight: 500;
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: var(--gap-sm);
  align-content: center;
}

.button-group {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  gap: var(--gap-md);
}
</style>
